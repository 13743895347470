import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { convertDegreesToRaDec } from "../helpers/functions";
import { selectRaDecValue } from "../features/dataAccess/dataAccessSlice";

function useScript(src) {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = src;
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [src]);
}

const AladinComponent = () => {
  const { ra, dec } = useSelector(selectRaDecValue);

  useScript("https://code.jquery.com/jquery-3.6.0.min.js");
  useScript(
    "https://aladin.u-strasbg.fr/AladinLite/api/v2/latest/aladin.min.js"
  );

  useEffect(() => {
    try {
      if (window.A) {
        const { raHour, raMin, raSec, decDeg, decMin, decSec } =
          convertDegreesToRaDec(ra, dec);

        let aladin = window.A.aladin("#aladin-lite-div", {
          target: `${raHour} ${raMin} ${raSec} ${decDeg} ${decMin} ${decSec}`,
          survey: "P/DSS2/color",
        });

        aladin.setFov(0.1);
        aladin.gotoRaDec(ra, dec);

        aladin.on("zoomChanged", function (newZoomValue) {
          if (newZoomValue > 160) {
            aladin.setZoom(160);
          }
        });
      }
    } catch (error) {
      console.error("An error occurred with Aladin Lite: ", error);
    }
  }, [ra, dec]);

  return (
    <div
      style={{
        width: "100%",
        paddingTop: "60%",
        position: "relative",
        userSelect: "none",
      }}
    >
      <div
        id="aladin-lite-div"
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        }}
      ></div>
    </div>
  );
};

export default AladinComponent;
