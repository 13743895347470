import axios from "../axiosConfig";
import { saveAs } from "file-saver";

export const subscribe = async (email) => {
  try {
    const response = await axios.post(`/subscribe/`, {
      email: email,
    });
    return response.data;
  } catch (error) {
    console.error("Error subscribing: ", error);
    throw error;
  }
};

export const sendEmail = async (data) => {
  try {
    const response = await axios.post(`/send_email/`, data);
    return response.data;
  } catch (error) {
    console.error("Error sending email: ", error);
    throw error;
  }
};

export const getModelDescription = async () => {
  try {
    const response = await axios.get("/serve-pdf/data_access/", {
      responseType: "blob",
    });

    const file = new Blob([response.data], {
      type: "application/pdf",
    });

    saveAs(file, "Online_Documentation_Data_Access.pdf");

    return true;
  } catch (error) {
    console.error("Download error:", error);

    return false;
  }
};
