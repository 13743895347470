import React from "react";
import "../styles/whyUs.css";

const WhyUs = () => {
  return (
    <div id="why-us" className="why-us">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 box-wrapper" data-aos="fade-up">
            <div className="box">
              <span style={{ fontSize: "24px" }}>IR and optical/UV data</span>
              <br />
              <p>- IR Data: WISE/NEOWISE (3.4, 4.6, 12, 22 μm)</p>
              <p>
                - Optical Data: ASAS-SN (V, g-band), ZTF (g, r, i), UVOT (V, B,
                U)
              </p>
              <p>- UV Data: UVOT (W1, M2, W2)</p>
            </div>
          </div>

          <div
            className="col-lg-4 mt-4 mt-lg-0 box-wrapper"
            data-aos="fade-up"
            data-aos-delay="150"
          >
            <div className="box">
              <span style={{ fontSize: "24px" }}>X-ray data</span>
              <br />
              <p>
                - 0.3-10 keV from each XRT observation (ObsID) <br />- 3.0-79
                keV from each NuSTAR observation (ObsID)
              </p>
            </div>
          </div>

          <div
            className="col-lg-4 mt-4 mt-lg-0 box-wrapper"
            data-aos="fade-up"
            data-aos-delay="300"
          >
            <div className="box">
              <span>Gamma-ray data</span>
              <br />
              <p>
                - Adaptively binned light curve computed for the period from
                2008 to 2023.
                <br /> - Divided into piece-wise constant Bayesian blocks.
                <br /> - Spectral analysis applied to each interval.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyUs;
