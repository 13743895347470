import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Slider, Typography } from "@mui/material";
import {
  selectMjdRange,
  setMjdRange,
} from "../features/dataAccess/dataAccessSlice";
import styles from "../styles/filterByMJD.module.css";

const FilterByMJD = ({ setLastButtonClicked }) => {
  const dispatch = useDispatch();
  const mjdRange = useSelector(selectMjdRange);
  const [error, setError] = useState("");

  const handleMjdRangeChange = (e, newValue) => {
    dispatch(setMjdRange([newValue[0], newValue[1]]));
  };

  const handleRangeCommit = () => {
    const [start, end] = mjdRange;

    if (start >= 54000 && end <= 60700) {
      setError("");
      dispatch(setMjdRange([start, end]));
      setLastButtonClicked("FilterByMJD");
    } else {
      setError("MJD values must be between 54000 and 60700");
    }
  };

  return (
    <div className={styles.inputsWrapper}>
      <Slider
        value={mjdRange}
        onChange={handleMjdRangeChange}
        onChangeCommitted={handleRangeCommit}
        valueLabelDisplay="auto"
        min={54000}
        max={60700}
        step={1}
        sx={{
          width: "100%",
          maxWidth: "150px",
          marginLeft: "10px",
          ".MuiOutlinedInput-root": {
            backgroundColor: "#ffffff",
          },
        }}
      />

      {error && <Typography color="error">{error}</Typography>}

      <Button
        type="submit"
        variant="contained"
        className={styles.filterBtn}
        onClick={() => setLastButtonClicked("FilterByMJD")}
      >
        Filter
      </Button>
    </div>
  );
};

export default FilterByMJD;
