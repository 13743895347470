import React, { useState } from "react";
import PlotDataInput from "./PlotDataInput";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  handleShowSections,
  selectRaDecValue,
  selectSearchText,
  selectShowPlotLoader,
  selectShowSections,
  selectShowSourceInfo,
  selectSourceName,
  selectValueDb,
} from "../features/dataAccess/dataAccessSlice";
import ForceRunCheckbox from "./ForceRunCheckbox";
import DataPolicy from "./DataPolicy";
import DownloadCSV from "./DownloadCSV";
import SelectCoordinatesFromKnownBlazars from "./SelectCoordinatesFromKnownBlazars";
import SelectCoordinatesFromGammaRayBlazars from "./SelectCoordinatesFromGammaRayBlazars";
import styles from "../styles/SearchPlotWithInputData.module.css";

const SearchPlot = () => {
  const dispatch = useDispatch();
  const searchText = useSelector(selectSearchText);
  const showSourceInfo = useSelector(selectShowSourceInfo);
  const showPlotLoader = useSelector(selectShowPlotLoader);
  const showSections = useSelector(selectShowSections);
  const { ra, dec } = useSelector(selectRaDecValue);
  const sourceName = useSelector(selectSourceName);
  const valueDB = useSelector(selectValueDb);

  const [openAccordion, setOpenAccordion] = useState(null);

  const handleShowSectionsButtonClick = () => {
    dispatch(handleShowSections({ sourceName, ra, dec, valueDB }));
  };

  const handleAccordionToggle = (accordionName) => {
    setOpenAccordion((prevAccordion) =>
      prevAccordion === accordionName ? null : accordionName
    );
  };

  return (
    <div className={styles.wrapper} id="scrollToDataWithInputData">
      <div className={styles.leftPart}>
        <div className={styles.selectsWrapper}>
          <SelectCoordinatesFromKnownBlazars
            open={openAccordion === "knownBlazars"}
            isGammaRayOpen={openAccordion === "gammaRayBlazars"}
            onToggle={() => handleAccordionToggle("knownBlazars")}
          />
          <SelectCoordinatesFromGammaRayBlazars
            open={openAccordion === "gammaRayBlazars"}
            isInKnownOpen={openAccordion === "knownBlazars"}
            onToggle={() => handleAccordionToggle("gammaRayBlazars")}
          />
        </div>

        <PlotDataInput />
        <div className={styles.buttonsWrapper}>
          <Button
            sx={{ height: "fit-content" }}
            variant="contained"
            size="medium"
            onClick={handleShowSectionsButtonClick}
            disabled={searchText.length <= 2}
          >
            Plot
          </Button>

          <ForceRunCheckbox />
        </div>
      </div>

      {showSourceInfo && <DataPolicy />}

      {!showPlotLoader && showSections && <DownloadCSV />}
    </div>
  );
};

export default SearchPlot;
