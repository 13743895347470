import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { FixedSizeList as List } from "react-window";
import {
  ListItem,
  ListItemText,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  setDataInKnown,
  selectDataInKnown,
  setSearchText,
  setValueDB,
  setSourceName,
  setRaDecValue,
  setError,
} from "../features/dataAccess/dataAccessSlice";
import styles from "../styles/selectCoordinatesFromTable.module.css";

function SelectCoordinatesFromKnownBlazars({ open, onToggle, isGammaRayOpen }) {
  const dispatch = useDispatch();
  const knownBlazarsAccordionRef = useRef();

  const [wrapperWidth, setWrapperWidth] = useState(0);

  const dataInKnown = useSelector(selectDataInKnown);

  useEffect(() => {
    // Update the width state whenever the wrapper size changes
    const updateWidth = () => {
      if (knownBlazarsAccordionRef.current) {
        setWrapperWidth(knownBlazarsAccordionRef.current.offsetWidth);
      }
    };

    // Call updateWidth on mount
    updateWidth();

    // Set event listener for window resize to dynamically adjust width
    window.addEventListener("resize", updateWidth);

    return () => {
      window.removeEventListener("resize", updateWidth);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        knownBlazarsAccordionRef.current &&
        !knownBlazarsAccordionRef.current.contains(event.target) &&
        open
      ) {
        onToggle();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    const fetchData = async () => {
      try {
        const response = await axios.get("/known_sources");
        dispatch(setDataInKnown(response.data));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dispatch, onToggle, open]);

  const handleItemClick = (item) => {
    const { name, ra, dec } = item;

    dispatch(setValueDB("SSDC"));
    dispatch(setSourceName(name));
    dispatch(setRaDecValue({ ra: ra, dec: dec }));
    dispatch(setSearchText(`${name} RA: ${ra} DEC: ${dec}`));
    dispatch(setError(null));
    onToggle(false);
  };

  const Row = ({ index, style }) => (
    <ListItem
      style={style}
      key={index}
      onClick={() => handleItemClick(dataInKnown[index], index)}
    >
      <ListItemText
        primary={dataInKnown[index].name}
        style={{ width: "200px" }}
      />
      <ListItemText
        primary={`RA: ${dataInKnown[index].ra}`}
        style={{ width: "120px" }}
      />
      <ListItemText
        primary={`DEC: ${dataInKnown[index].dec}`}
        style={{ width: "120px" }}
      />
    </ListItem>
  );

  return (
    <div className={styles.accordionWrapper}>
      <Accordion
        ref={knownBlazarsAccordionRef}
        className={styles.accordion}
        expanded={open}
        sx={{
          height: "100%",
          borderTopLeftRadius: "4px!important",
          borderTopRightRadius: "4px",
          borderBottomLeftRadius:
            open || isGammaRayOpen ? "0!important" : "4px!important",
          borderBottomRightRadius:
            open || isGammaRayOpen ? "0!important" : "4px!important",
          "& > div:last-of-type": {
            position: "absolute",
            left: 0,
            zIndex: 999,
            backgroundColor: "white",
            transition: "height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
            transitionDuration: "315ms",
            boxShadow:
              "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
          },
          "@media screen and (max-width: 990px)": {
            "& > div:last-of-type": {
              width: "100%",
            },
          },
          "@media screen and (min-width: 991px)": {
            "& > div:last-of-type": {
              width: `${wrapperWidth}px`,
            },
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          classes={{
            content: styles.accordionSummaryContent,
            expanded: `${styles.customAccordionSummary}`,
          }}
          onClick={onToggle}
        >
          <Typography>List of Known Blazars</Typography>
        </AccordionSummary>
        <AccordionDetails className={styles.accordionDetails}>
          <div style={{ height: "300px", overflow: "auto" }}>
            <List
              height={300}
              itemCount={dataInKnown.length}
              itemSize={30}
              className={styles.dataInKnownList}
            >
              {Row}
            </List>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default SelectCoordinatesFromKnownBlazars;
