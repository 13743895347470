// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".forceRun_forceRunWrapper__vb5Mf {\n  display: flex;\n  align-items: center;\n  gap: 4px;\n}\n", "",{"version":3,"sources":["webpack://./src/styles/forceRun.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,QAAQ;AACV","sourcesContent":[".forceRunWrapper {\n  display: flex;\n  align-items: center;\n  gap: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"forceRunWrapper": "forceRun_forceRunWrapper__vb5Mf"
};
export default ___CSS_LOADER_EXPORT___;
