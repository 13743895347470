import axios from "../axiosConfig";

export const subscribe = async (email) => {
  try {
    const response = await axios.post(`/subscribe/`, {
      email: email,
    });
    return response.data;
  } catch (error) {
    console.error("Error subscribing: ", error);
    throw error;
  }
};

export const sendEmail = async (data) => {
  try {
    const response = await axios.post(`/send_email/`, data);
    return response.data;
  } catch (error) {
    console.error("Error sending email: ", error);
    throw error;
  }
};
