import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { FixedSizeList as List } from "react-window";
import {
  ListItem,
  ListItemText,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  setDataInKnown,
  selectDataInKnown,
  setSearchText,
  setValueDB,
  setSourceName,
  setRaDecValue,
  setError,
} from "../features/dataAccess/dataAccessSlice";
import styles from "../styles/selectCoordinatesFromTable.module.css";

function SelectCoordinatesFromTable() {
  const dispatch = useDispatch();
  const accordionRef = useRef();
  const [open, setOpen] = useState(false);
  const dataInKnown = useSelector(selectDataInKnown);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        accordionRef.current &&
        !accordionRef.current.contains(event.target)
      ) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    const fetchData = async () => {
      try {
        const response = await axios.get("/known_sources");
        dispatch(setDataInKnown(response.data));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dispatch, setOpen]);

  const toggleAccordion = () => {
    setOpen(!open);
  };

  const handleItemClick = (item) => {
    const { name, ra, dec } = item;

    dispatch(setValueDB("SSDC"));
    dispatch(setSourceName(name));
    dispatch(setRaDecValue({ ra: ra, dec: dec }));
    dispatch(setSearchText(`${name} RA: ${ra} DEC: ${dec}`));
    dispatch(setError(null));
    setOpen(false);
  };

  const Row = ({ index, style }) => (
    <ListItem
      style={style}
      key={index}
      onClick={() => handleItemClick(dataInKnown[index], index)}
    >
      <ListItemText
        primary={dataInKnown[index].name}
        style={{ width: "200px" }}
      />
      <ListItemText
        primary={`RA: ${dataInKnown[index].ra}`}
        style={{ width: "120px" }}
      />
      <ListItemText
        primary={`DEC: ${dataInKnown[index].dec}`}
        style={{ width: "120px" }}
      />
    </ListItem>
  );

  return (
    <Accordion ref={accordionRef} className={styles.accordion} expanded={open}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        classes={{
          content: styles.accordionSummaryContent,
          expanded: `${styles.customAccordionSummary}`,
        }}
        onClick={toggleAccordion}
      >
        <Typography>List of Known Blazars</Typography>
      </AccordionSummary>
      <AccordionDetails className={styles.accordionDetails}>
        <div style={{ height: "300px", overflow: "auto" }}>
          <List
            height={300}
            width={"600px"}
            itemCount={dataInKnown.length}
            itemSize={30}
            className={styles.dataInKnownList}
          >
            {Row}
          </List>
        </div>
      </AccordionDetails>
    </Accordion>
  );
}

export default SelectCoordinatesFromTable;
