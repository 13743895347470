import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from "@mui/material/Tooltip"; // Import Tooltip
import {
  fetchSearchResults,
  selectSearchResultsForView,
  selectSearchText,
  selectLogs,
  setError,
  setRaDecValue,
  setSearchResults,
  setSearchResultsForView,
  setSearchResultsIndex,
  setSearchText,
  setSourceName,
  setValueDB,
  selectSearchTextLoading,
  selectOptionsError,
} from "../features/dataAccess/dataAccessSlice";
import { Box, Button } from "@mui/material";
import LogDrawer from "./LogDrawer";
import styles from "../styles/plotDataInput.module.css";

let delayTimer;

const PlotDataInput = () => {
  const dispatch = useDispatch();
  const [showLogs, setShowLogs] = useState(false);
  const [isAutocompleteOpen, setIsAutocompleteOpen] = useState(false);
  const searchText = useSelector(selectSearchText);
  const searchResultsForView = useSelector(selectSearchResultsForView);
  const optionsError = useSelector(selectOptionsError);
  const isSearchTextLoading = useSelector(selectSearchTextLoading);
  const logs = useSelector(selectLogs);

  const isEqual = (option, value) =>
    option?.text === value?.text &&
    option?.valueRA === value?.valueRA &&
    option?.valueDEC === value?.valueDEC;

  useEffect(() => {
    if (searchResultsForView.length > 0) {
      setIsAutocompleteOpen(true);
    } else {
      setIsAutocompleteOpen(false);
    }
  }, [searchResultsForView]);

  const handleBlur = () => {
    setIsAutocompleteOpen(false);
  };

  const handleClick = () => {
    if (searchResultsForView.length > 0) {
      setIsAutocompleteOpen(true);
    }
  };

  const handleInputChange = (e, value) => {
    if (e !== null) {
      const isChange = e.type === "change";
      dispatch(setSearchText(value));

      if (isChange) {
        clearTimeout(delayTimer);

        delayTimer = setTimeout(() => {
          if (value.length > 2) {
            dispatch(fetchSearchResults(value));
          }
          if (value.length === 0) {
            dispatch(setSearchResults([]));
            dispatch(setSearchResultsForView([]));
            dispatch(setSearchResultsIndex(null));
          }
        }, 500);
      }
    }
  };

  const handleItemSelected = (value) => {
    if (value) {
      const { text, valueDB, valueRA, valueDEC } = value;

      const selectedIndex = searchResultsForView.findIndex((option) =>
        isEqual(option, value)
      );

      dispatch(setSearchResultsIndex(selectedIndex));
      dispatch(setValueDB(valueDB));
      dispatch(setSourceName(text));
      dispatch(setRaDecValue({ ra: valueRA, dec: valueDEC }));
      dispatch(setSearchText(`${text} RA: ${valueRA} DEC: ${valueDEC}`));
      setIsAutocompleteOpen(false);
      dispatch(setError(null));
    } else {
      dispatch(setSearchText(""));
      dispatch(setSearchResults([]));
      dispatch(setSearchResultsForView([]));
    }
  };

  const handleToggleLogs = () => {
    setShowLogs((prevState) => !prevState);
  };

  return (
    <div className={styles.listWrapper}>
      <Autocomplete
        inputValue={searchText}
        options={searchResultsForView}
        open={isAutocompleteOpen}
        clearOnBlur={false}
        isOptionEqualToValue={isEqual}
        onInputChange={(e, val) => handleInputChange(e, val)}
        onChange={(e, val) => handleItemSelected(val)}
        noOptionsText={optionsError || "No options"}
        getOptionLabel={({ text, valueRA, valueDEC, valueDB }) =>
          `${text} RA: ${valueRA} DEC: ${valueDEC} ${valueDB}`
        }
        filterOptions={(options) => options}
        renderInput={(params) => (
          <Tooltip
            title="When the source name is provided, coordinates from the Space Science Data Center (SSDC) are used. For some sources, there are offsets between SSDC and NASA/IPAC Extragalactic Database (NED) coordinates. If no results are found, try using coordinates from NED."
            arrow
          >
            <Box display="flex" flexDirection="row" alignItems="center" gap={2}>
              <TextField
                {...params}
                label="Source Name"
                variant="outlined"
                onBlur={handleBlur}
                onFocus={handleClick}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <Fragment>
                      {isSearchTextLoading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </Fragment>
                  ),
                }}
                sx={{
                  width: "100%",
                  maxWidth: "600px",
                  ".MuiOutlinedInput-root": {
                    backgroundColor: "#ffffff",
                  },
                }}
              />
              {logs && (
                <Button
                  variant="contained"
                  style={{ width: "max-content" }}
                  onClick={handleToggleLogs}
                >
                  Show Logs
                </Button>
              )}
            </Box>
          </Tooltip>
        )}
      />

      <LogDrawer logs={logs} showLogs={showLogs} closeLogs={handleToggleLogs} />
    </div>
  );
};

export default PlotDataInput;
