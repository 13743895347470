import React from "react";
import { useSelector } from "react-redux";
import {
  selectSedLcError,
  selectSedLcIsLoading,
  selectSedLcVideoUrl,
} from "../features/dataAccess/dataAccessSlice";
import styles from "../styles/sedLcAnimationWrapper.module.css";

const SedLcAnimation = () => {
  const videoUrl = useSelector(selectSedLcVideoUrl);
  const isLoading = useSelector(selectSedLcIsLoading);
  const error = useSelector(selectSedLcError);

  return (
    <div>
      {isLoading && <p>Loading...</p>}

      {error && <p>{error}</p>}

      <div className={styles.sedLcAnimationVideo}>
        {videoUrl && (
          <video
            src={videoUrl}
            autoPlay
            muted
            playsInline
            alt="MP4 Animation"
            width="100%"
            height="100%"
          />
        )}
      </div>
    </div>
  );
};

export default SedLcAnimation;
