// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SearchPlotWithInputData_wrapper__LpgKJ {\n    display: flex;\n    column-gap: 26px;\n}\n\n.SearchPlotWithInputData_buttonsWrapper__0BgVE {\n    display: flex;\n    flex-direction: row;\n    column-gap: 6px;\n}\n\n.SearchPlotWithInputData_leftPart__beBOJ {\n    display: flex;\n    flex-direction: column;\n    row-gap: 6px;\n    width: 100%;\n}", "",{"version":3,"sources":["webpack://./src/styles/SearchPlotWithInputData.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,YAAY;IACZ,WAAW;AACf","sourcesContent":[".wrapper {\n    display: flex;\n    column-gap: 26px;\n}\n\n.buttonsWrapper {\n    display: flex;\n    flex-direction: row;\n    column-gap: 6px;\n}\n\n.leftPart {\n    display: flex;\n    flex-direction: column;\n    row-gap: 6px;\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "SearchPlotWithInputData_wrapper__LpgKJ",
	"buttonsWrapper": "SearchPlotWithInputData_buttonsWrapper__0BgVE",
	"leftPart": "SearchPlotWithInputData_leftPart__beBOJ"
};
export default ___CSS_LOADER_EXPORT___;
