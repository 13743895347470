import React from "react";
import styles from "../styles/filterByMJD.module.css";
import { Button, TextField } from "@mui/material";
import {
  selectFilterInputs,
  setFilterInputs,
} from "../features/dataAccess/dataAccessSlice";
import { useDispatch, useSelector } from "react-redux";

const FilterByMJD = ({ setLastButtonClicked }) => {
  const dispatch = useDispatch();
  const filterInputs = useSelector(selectFilterInputs);

  const handleInputsChange = (event) => {
    dispatch(
      setFilterInputs({
        ...filterInputs,
        [event.target.name]: event.target.value || null,
      })
    );
  };

  return (
    <div className={styles.inputsWrapper}>
      <TextField
        label="MJD start"
        type="number"
        value={filterInputs.start || ""}
        onChange={handleInputsChange}
        name="start"
        size="small"
        sx={{
          width: "100%",
          ".MuiOutlinedInput-root": {
            backgroundColor: "#ffffff",
          },
          "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
            {
              WebkitAppearance: "none",
              margin: 0,
            },
          "& input[type=number]": {
            MozAppearance: "textfield",
          },
        }}
      />
      <TextField
        label="MJD end"
        type="number"
        value={filterInputs.end || ""}
        onChange={handleInputsChange}
        name="end"
        size="small"
        sx={{
          width: "100%",
          ".MuiOutlinedInput-root": {
            backgroundColor: "#fff",
          },
          "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
            {
              WebkitAppearance: "none",
              margin: 0,
            },
          "& input[type=number]": {
            MozAppearance: "textfield",
          },
        }}
      />

      <Button
        type="submit"
        variant="contained"
        className={styles.filterBtn}
        onClick={() => setLastButtonClicked("FilterByMJD")}
      >
        Filter
      </Button>
    </div>
  );
};

export default FilterByMJD;
