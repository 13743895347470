import { useDispatch, useSelector } from "react-redux";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import {
  selectForceRun,
  setForceRun,
} from "../features/dataAccess/dataAccessSlice";
import { styled, Tooltip, tooltipClasses } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import styles from "../styles/forceRun.module.css";

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
  },
});

const ForceRunCheckbox = () => {
  const dispatch = useDispatch();
  const forceRun = useSelector(selectForceRun);

  return (
    <FormControlLabel
      sx={{ margin: 0 }} // remove margin
      control={
        <Checkbox
          sx={{ padding: 0 }} // remove padding
          checked={forceRun}
          onChange={(e) => dispatch(setForceRun(e.target.checked))}
        />
      }
      label={
        <div className={styles.forceRunWrapper}>
          <Typography
            align="left"
            variant="body1"
            style={{ userSelect: "none" }}
          >
            Force Run
          </Typography>
          <CustomWidthTooltip
            title={
              <Typography sx={{ textAlign: "center" }}>
                Selecting this option initiates the data-gathering process,
                accessing various catalogs and returning the results. This is
                useful if SHOW LOGS indicate that some catalogs were unreachable
                in the previous run.
              </Typography>
            }
          >
            <InfoOutlinedIcon fontSize="small" />
          </CustomWidthTooltip>
        </div>
      }
    />
  );
};

export default ForceRunCheckbox;
