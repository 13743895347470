import React from "react";
import "../styles/whyUs.css";

const WhyUs = () => {
  return (
    <div id="why-us" className="why-us">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 box-wrapper" data-aos="fade-up">
            <div className="box">
              <span style={{ fontSize: "24px" }}>IR and optical/UV data</span>
              <br />
              <p>
                - IR Data:{" "}
                <a
                  href="https://neowise.ipac.caltech.edu"
                  target="_blank"
                  rel="noreferrer"
                >
                  WISE/NEOWISE
                </a>{" "}
                (3.4, 4.6, 12, 22 μm)
              </p>
              <p>
                - Optical Data:{" "}
                <a
                  href="http://asas-sn.ifa.hawaii.edu/skypatrol/"
                  target="_blank"
                  rel="noreferrer"
                >
                  ASAS-SN (V, g-band)
                </a>
                ,{" "}
                <a
                  href="https://www.ztf.caltech.edu"
                  target="_blank"
                  rel="noreferrer"
                >
                  ZTF (g, r, i)
                </a>
                ,{" "}
                <a
                  href="https://swift.gsfc.nasa.gov/about_swift/uvot_desc.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  UVOT (V, B, U)
                </a>
                ,{" "}
                <a
                  href="https://outerspace.stsci.edu/display/PANSTARRS/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Pan-STARRS1 (g, r, i, z, y)
                </a>
              </p>
              <p>
                - UV Data:{" "}
                <a
                  href="https://swift.gsfc.nasa.gov/about_swift/uvot_desc.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  UVOT (W1, M2, W2)
                </a>
              </p>
            </div>
          </div>

          <div
            className="col-lg-4 mt-4 mt-lg-0 box-wrapper"
            data-aos="fade-up"
            data-aos-delay="150"
          >
            <div className="box">
              <span style={{ fontSize: "24px" }}>X-ray data</span>
              <br />
              <a
                href="https://swift.gsfc.nasa.gov/about_swift/xrt_desc.html"
                target="_blank"
                rel="noreferrer"
              >
                - 0.3-10 keV from each XRT observation (ObsID)
              </a>{" "}
              <br />
              <a
                href="https://www.nustar.caltech.edu"
                target="_blank"
                rel="noreferrer"
              >
                - 3.0-79 keV from each NuSTAR observation (ObsID)
              </a>
            </div>
          </div>

          <div
            className="col-lg-4 mt-4 mt-lg-0 box-wrapper"
            data-aos="fade-up"
            data-aos-delay="300"
          >
            <div className="box">
              <span>Gamma-ray data</span>
              <br />
              <p>
                - Adaptively binned light curve computed for the period from
                2008 to 2023.
                <br /> - Divided into piece-wise constant Bayesian blocks.
                <br /> - Spectral analysis applied to each interval.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyUs;
