import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import {
  fetchTheoreticalModelingPlot,
  setSscSearchParams,
  selectSearchParams,
  setModelType,
  setEicSearchParams,
} from "../features/theoreticalModeling/theoreticalModelingSlice";
import SSCInputs from "./SSCInputs";
import EICInputs from "./EICInputs";
import styles from "../styles/searchPlotData.module.css";

const sscInputConstraints = {
  z: { min: 0, max: 10 },
  δ: { min: 3, max: 50 },
  "log10(R/[cm])": { min: 15, max: 18 },
  "log10(γmin)": { min: 1.5, max: 5 },
  "log10(γmax)": { min: 2, max: 8 },
  p: { min: 1.8, max: 5 },
  "log10(Le/[erg s⁻¹])": { min: 42, max: 48 },
  "log10(B/[G])": { min: -3, max: 2 },
};

const eicInputConstraints = {
  z: { min: 0, max: 10 },
  δ: { min: 3, max: 50 },
  "log10(R/[cm])": { min: 15, max: 18 },
  "log10(γmin)": { min: 1.5, max: 5 },
  "log10(γmax)": { min: 2, max: 6 },
  p: { min: 1.8, max: 5 },
  "log10(Le/[erg s⁻¹])": { min: 42, max: 48 },
  "log10(B/[G])": { min: -3, max: 2.5 },
  "log10(Ld/[erg s⁻¹])": { min: 43.5, max: 47 },
  "log10(M⊙)": { min: 7, max: 10 },
  "log10(νBLR)": { min: 14.5, max: 16 },
  "log10(νDT)": { min: 12.5, max: 14 },
};

const RunModel = ({ inputErrors, setInputErrors }) => {
  const dispatch = useDispatch();
  const { checkboxData, sscInputsData, eicInputsData } =
    useSelector(selectSearchParams);

  const handleSubmit = (event) => {
    event.preventDefault();

    let newInputErrors = { ...inputErrors };
    let isFormValid = true;

    if (checkboxData.value === "SSC") {
      sscInputsData.forEach(({ label, value }) => {
        if (label !== "EBL") {
          if (!value) {
            newInputErrors[label] = "Required field";
            isFormValid = false;
          }

          if (inputErrors.ssc[label]) {
            isFormValid = false;
          }
        }
      });

      setInputErrors(newInputErrors);

      if (isFormValid) {
        dispatch(fetchTheoreticalModelingPlot());
      }
    }

    if (checkboxData.value === "EIC") {
      eicInputsData.forEach(({ label, value }) => {
        if (label !== "EBL") {
          if (!value) {
            newInputErrors[label] = "Required field";
            isFormValid = false;
          }

          if (inputErrors.eic[label]) {
            isFormValid = false;
          }
        }
      });

      setInputErrors(newInputErrors);

      if (isFormValid) {
        dispatch(fetchTheoreticalModelingPlot());
      }
    }
  };

  const handleSscSearchChange = (e, label, id) => {
    let value = e.target.value;

    if (value[0] === ".") {
      return;
    }

    if (value.includes(".")) {
      let parts = value.split(".");
      if (parts[1].length > 3) {
        value = parts[0] + "." + parts[1].substring(0, 3);
      }
    }

    const { min, max } = sscInputConstraints[label] || {};

    if (value) {
      if (label === "z" && (value <= min || value > max)) {
        setInputErrors({
          ...inputErrors,
          ssc: {
            ...inputErrors.ssc,
            [label]: `> ${min} up to ${max}`,
          },
        });
      } else if (value < min || value > max) {
        setInputErrors({
          ...inputErrors,
          ssc: {
            ...inputErrors.ssc,
            [label]: `From ${min} to ${max}`,
          },
        });
      } else {
        setInputErrors({
          ...inputErrors,
          ssc: {
            ...inputErrors.ssc,
            [label]: "",
          },
        });
      }
    } else {
      setInputErrors({
        ...inputErrors,
        ssc: {
          ...inputErrors.ssc,
          [label]: "",
        },
      });
    }

    dispatch(setSscSearchParams({ id, value }));
  };

  const handleEicSearchChange = (e, label, id) => {
    let value = e.target.value;

    if (value[0] === ".") {
      return;
    }

    if (value.includes(".")) {
      let parts = value.split(".");
      if (parts[1].length > 3) {
        value = parts[0] + "." + parts[1].substring(0, 3);
      }
    }

    const { min, max } = eicInputConstraints[label] || {};

    if (value) {
      if (label === "z" && (value <= min || value > max)) {
        setInputErrors({
          ...inputErrors,
          eic: {
            ...inputErrors.eic,
            [label]: `> ${min} up to ${max}`,
          },
        });
      } else if (value < min || value > max) {
        setInputErrors({
          ...inputErrors,
          eic: {
            ...inputErrors.eic,
            [label]: `From ${min} to ${max}`,
          },
        });
      } else {
        setInputErrors({
          ...inputErrors,
          eic: {
            ...inputErrors.eic,
            [label]: "",
          },
        });
      }
    } else {
      setInputErrors({
        ...inputErrors,
        eic: {
          ...inputErrors.eic,
          [label]: "",
        },
      });
    }

    dispatch(setEicSearchParams({ id, value }));
  };

  const handleModelTypeChange = (e) => {
    const value = e.target.value;

    dispatch(
      setModelType({
        ...checkboxData,
        value: value,
      })
    );
  };

  return (
    <div>
      <Box
        component="form"
        onSubmit={handleSubmit}
        display={"flex"}
        flexDirection={"column"}
        gap={"12px"}
      >
        <RadioGroup
          className={styles.radioGroupWrapper}
          name="modelType"
          value={checkboxData.value}
          onChange={handleModelTypeChange}
          row
        >
          {checkboxData.name.map((name, index) => (
            <FormControlLabel
              key={name}
              value={name}
              control={<Radio className={styles.radioCircles} size="small" />}
              label={checkboxData.label[index]}
              className={checkboxData.value === name ? styles.activeRadio : ""}
              disabled={name === "Hadronic"}
            />
          ))}
        </RadioGroup>

        {checkboxData.value === "SSC" && (
          <SSCInputs
            inputErrors={inputErrors.ssc}
            handleSscSearchChange={handleSscSearchChange}
          />
        )}
        {checkboxData.value === "EIC" && (
          <EICInputs
            inputErrors={inputErrors.eic}
            handleEicSearchChange={handleEicSearchChange}
          />
        )}

        <Button
          variant="contained"
          color="primary"
          fullWidth
          type="submit"
          size="small"
        >
          Run model
        </Button>
      </Box>
    </div>
  );
};

export default RunModel;
