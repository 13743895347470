// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SearchPlotWithInputData_selectsWrapper__s6Rz5 {\n  position: relative;\n  display: flex;\n  gap: 10px;\n  margin-bottom: 10px;\n}\n\n.SearchPlotWithInputData_wrapper__LpgKJ {\n  display: flex;\n  column-gap: 26px;\n}\n\n.SearchPlotWithInputData_buttonsWrapper__0BgVE {\n  display: flex;\n  flex-direction: row;\n  column-gap: 6px;\n}\n\n.SearchPlotWithInputData_leftPart__beBOJ {\n  display: flex;\n  flex-direction: column;\n  row-gap: 6px;\n  width: 100%;\n}\n", "",{"version":3,"sources":["webpack://./src/styles/SearchPlotWithInputData.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,SAAS;EACT,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,WAAW;AACb","sourcesContent":[".selectsWrapper {\n  position: relative;\n  display: flex;\n  gap: 10px;\n  margin-bottom: 10px;\n}\n\n.wrapper {\n  display: flex;\n  column-gap: 26px;\n}\n\n.buttonsWrapper {\n  display: flex;\n  flex-direction: row;\n  column-gap: 6px;\n}\n\n.leftPart {\n  display: flex;\n  flex-direction: column;\n  row-gap: 6px;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selectsWrapper": "SearchPlotWithInputData_selectsWrapper__s6Rz5",
	"wrapper": "SearchPlotWithInputData_wrapper__LpgKJ",
	"buttonsWrapper": "SearchPlotWithInputData_buttonsWrapper__0BgVE",
	"leftPart": "SearchPlotWithInputData_leftPart__beBOJ"
};
export default ___CSS_LOADER_EXPORT___;
