import { useDispatch, useSelector } from "react-redux";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import {
  selectForceRun,
  setForceRun,
} from "../features/dataAccess/dataAccessSlice";

const ForceRunCheckbox = () => {
  const dispatch = useDispatch();
  const forceRun = useSelector(selectForceRun);

  return (
    <FormControlLabel
      sx={{ margin: 0 }} // remove margin
      control={
        <Checkbox
          sx={{ padding: 0 }} // remove padding
          checked={forceRun}
          onChange={(e) => dispatch(setForceRun(e.target.checked))}
        />
      }
      label={
        <Typography align="left" variant="body1" style={{ userSelect: "none" }}>
          Force Run
        </Typography>
      }
    />
  );
};

export default ForceRunCheckbox;
