// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".filterByMJD_inputsWrapper__QDzEl {\n  display: flex;\n  flex-direction: column;\n  row-gap: 6px;\n  padding-right: 8px;\n  min-width: 120px;\n  max-width: 180px;\n  width: 100%;\n  margin-top: 8px;\n}\n\n.filterByMJD_filterBtn__Z20uA {\n  display: block;\n  margin: 8px auto;\n}\n", "",{"version":3,"sources":["webpack://./src/styles/filterByMJD.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,kBAAkB;EAClB,gBAAgB;EAChB,gBAAgB;EAChB,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,cAAc;EACd,gBAAgB;AAClB","sourcesContent":[".inputsWrapper {\n  display: flex;\n  flex-direction: column;\n  row-gap: 6px;\n  padding-right: 8px;\n  min-width: 120px;\n  max-width: 180px;\n  width: 100%;\n  margin-top: 8px;\n}\n\n.filterBtn {\n  display: block;\n  margin: 8px auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputsWrapper": "filterByMJD_inputsWrapper__QDzEl",
	"filterBtn": "filterByMJD_filterBtn__Z20uA"
};
export default ___CSS_LOADER_EXPORT___;
