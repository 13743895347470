import {
  Button,
  Tooltip,
  Typography,
  styled,
  tooltipClasses,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import DownloadIcon from "@mui/icons-material/Download";
import axios from "axios";
import { Link } from "react-scroll";
import { saveAs } from "file-saver";
import styles from "../styles/TMSourceInfo.module.css";

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
  },
});

const TMSourceInfo = () => {
  const handleDownload = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.get("/serve-pdf/theoretical_modeling", {
        responseType: "blob",
      });

      const file = new Blob([response.data], {
        type: "application/pdf",
      });
      // Use file-saver to save the file on the client side
      saveAs(file, "Online_Documentation_Theoretical_Modeling.pdf");
    } catch (error) {
      console.error("Download error:", error);
    }
  };

  return (
    <div>
      <div className={styles.contentWrapper}>
        <Button
          component="a"
          href="/"
          download
          className={styles.modelDescription}
          variant="contained"
          sx={{ display: "flex", gap: "6px" }}
          onClick={handleDownload}
        >
          Model description
          <DownloadIcon fontSize="small" />
        </Button>

        <CustomWidthTooltip
          title={
            <Typography sx={{ textAlign: "center" }}>
              If you use this web interface in your publication please cite the
              following paper:
              <br />
              <a
                href="https://ui.adsabs.harvard.edu/abs/2024ApJ...963...71B/abstract"
                target="_blank"
                className={styles.infoLink}
                rel="noreferrer"
              >
                Bégué, Sahakyan, Dereli Bégué, et al., 2024, ApJ, 963, 71
              </a>
              <br />
              <a
                href="https://ui.adsabs.harvard.edu/abs/2024arXiv240207495S/abstract"
                target="_blank"
                className={styles.infoLink}
                rel="noreferrer"
              >
                Sahakyan, Bégué, Casotto, et al., 2024
              </a>
            </Typography>
          }
        >
          <Button
            className={styles.citationBtn}
            variant="contained"
            onClick={(e) => e.preventDefault()}
            sx={{ columnGap: "6px" }}
          >
            CITATION
            <InfoOutlinedIcon fontSize="small" />
          </Button>
        </CustomWidthTooltip>

        <Link
          className={`scrollto ${styles.feedback}`}
          to="contact"
          spy={true}
          smooth={true}
          offset={-70}
          duration={0}
        >
          <Button variant="contained">Feedback</Button>
        </Link>
      </div>
    </div>
  );
};

export default TMSourceInfo;
