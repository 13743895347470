import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TreeView from "@mui/lab/TreeView";
import TreeItem from "@mui/lab/TreeItem";
import { styled } from "@mui/system";
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
  Checkbox,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  filterFetchCoordinates,
  selectAxisViews,
  selectGroupedLegends,
  setAxisViews,
  setExcludedRanges,
  setFilterDates,
  setGroupedLegends,
  setLoading,
  setMjdRange,
} from "../features/dataAccess/dataAccessSlice";
import { isEmptyObject } from "../helpers/functions";
import FilterByDate from "./FilterByDate";
import FilterByMJD from "./FilterByMJD";
import styles from "../styles/filterPlotData.module.css";

const LargeStyledTreeItem = styled(TreeItem)(({ theme }) => ({
  "& .MuiSvgIcon-root": {
    color: "#1976d2",
  },
  "&.largeTreeItem .MuiTreeItem-content": {
    borderRadius: "4px",
  },
  "&.largeTreeItem .MuiTreeItem-label": {
    fontSize: "18px",
    fontWeight: 500,
  },
  "&.largeTreeItem .MuiSvgIcon-root": {
    fontSize: "22px",
  },
  "&.largeTreeItem .MuiTreeItem-content:hover": {
    backgroundColor: "#cc1616",
    color: "#ffffff",
  },
  [theme.breakpoints.down(991)]: {
    "&.largeTreeItem": {
      minWidth: "200px",
    },
  },
}));

const SmallTreeItem = styled(TreeItem)(({ theme }) => ({
  "& .MuiSvgIcon-root": {
    color: "#1976d2",
  },
  "&.smallTreeItem .MuiTreeItem-label": {
    fontSize: "16px",
    fontWeight: "normal",
  },
}));

const Label = styled("div")(({ fontSize }) => ({
  display: "flex",
  alignItems: "center",
  color: "#000000",
  fontSize: fontSize,
}));

const ColorCircle = styled("div")(({ color }) => ({
  borderRadius: "50%",
  width: "10px",
  height: "10px",
  backgroundColor: color,
  marginLeft: "5px",
}));

const orderList = {
  radio_waves: "Radio",
  microwaves: "Microwaves",
  infrared: "Infrared",
  visible: "Optical",
  ultraviolet: "Ultraviolet",
  x_rays: "X-ray",
  gamma_rays: "Gamma ray",
};

const FilterPlotData = () => {
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState(["3"]);
  const [lastButtonClicked, setLastButtonClicked] = useState("");

  const groupedLegends = useSelector(selectGroupedLegends);
  const axisViews = useSelector(selectAxisViews);

  const handleSubmit = (event) => {
    event.preventDefault();

    dispatch(setLoading(true));

    if (lastButtonClicked === "FilterByMJD") {
      dispatch(
        setFilterDates({
          start: null,
          end: null,
        })
      );
    } else if (lastButtonClicked === "FilterByDate") {
      dispatch(setMjdRange([null, null]));
    }

    dispatch(filterFetchCoordinates({ resetGroupedLegends: true }));
  };

  const handleAxisViews = (e) => {
    const { name, value } = e.target;

    dispatch(
      setAxisViews({
        ...axisViews,
        [name]: {
          ...axisViews[name],
          value: value,
        },
      })
    );

    dispatch(filterFetchCoordinates({}));
  };

  const handleCheckboxChange = (e, nodeId) => {
    e.stopPropagation();

    const keys = nodeId.split("&");
    const newGroupedLegends = JSON.parse(JSON.stringify(groupedLegends));

    if (keys.length === 1) {
      // OuterKey
      const newShowValue = !newGroupedLegends[keys[0]].show;

      newGroupedLegends[keys[0]] = {
        ...newGroupedLegends[keys[0]],
        show: newShowValue,
        subCategories: Object.fromEntries(
          Object.entries(newGroupedLegends[keys[0]].subCategories).map(
            ([k, v]) => [k, { ...v, show: newShowValue }]
          )
        ),
      };
    } else if (keys.length === 2) {
      newGroupedLegends[keys[0]].subCategories[keys[1]] = {
        ...newGroupedLegends[keys[0]].subCategories[keys[1]],
        show: !newGroupedLegends[keys[0]].subCategories[keys[1]].show,
      };

      // If the outer key is disabled and the inner key is enabled, enable the outer key.
      if (
        !newGroupedLegends[keys[0]].show &&
        newGroupedLegends[keys[0]].subCategories[keys[1]].show
      ) {
        newGroupedLegends[keys[0]].show = true;
      } else {
        // If all the inner keys are disabled, disable the outer key.
        const allInnerKeysDisabled = Object.values(
          newGroupedLegends[keys[0]].subCategories
        ).every((innerKey) => !innerKey.show);

        if (allInnerKeysDisabled) {
          newGroupedLegends[keys[0]].show = false;
        }
      }
    }

    dispatch(setGroupedLegends(newGroupedLegends));

    // Prepare the excluded catalogs and ranges for the request
    const excludedCatalogs = [];
    const excludedFreqRanges = [];

    Object.entries(newGroupedLegends).forEach(
      ([outerKey, { show, subCategories }]) => {
        if (!show) {
          excludedFreqRanges.push(outerKey);
        } else {
          Object.entries(subCategories).forEach(([innerKey, { show }]) => {
            if (!show) {
              excludedCatalogs.push(innerKey);
            }
          });
        }
      }
    );

    dispatch(
      setExcludedRanges({
        catalogs: excludedCatalogs,
        freqFlux: excludedFreqRanges,
      })
    );

    dispatch(filterFetchCoordinates({}));
  };

  const handleNodeToggle = (event, nodes) => {
    if (["1", "2", "3"].includes(nodes[0])) {
      setExpanded([nodes[0]]);
    } else if (nodes[0] === "6") {
      const filteredNodes = nodes.filter((node) => node !== "7");
      setExpanded(filteredNodes);
    } else if (nodes[0] === "7") {
      const filteredNodes = nodes.filter((node) => node !== "6");
      setExpanded(filteredNodes);
    } else {
      setExpanded(nodes);
    }
  };

  return (
    <Box
      className={styles.filterFormWrapper}
      component="form"
      onSubmit={handleSubmit}
    >
      <TreeView
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        className={styles.filterTreeViewWrapper}
        expanded={expanded}
        onNodeToggle={handleNodeToggle}
        sx={{
          "& .css-1g86id8-MuiTreeItem-content": {
            padding: 0,
          },
        }}
      >
        <LargeStyledTreeItem
          className={`largeTreeItem ${styles.timeFiltering}`}
          nodeId="1"
          label="Time Filtering"
        >
          <SmallTreeItem className="smallTreeItem" nodeId="6" label="MJD">
            <FilterByMJD setLastButtonClicked={setLastButtonClicked} />
          </SmallTreeItem>
          <SmallTreeItem className="smallTreeItem" nodeId="7" label="Date">
            <FilterByDate setLastButtonClicked={setLastButtonClicked} />
          </SmallTreeItem>
        </LargeStyledTreeItem>

        <LargeStyledTreeItem
          className="largeTreeItem"
          nodeId="2"
          label="Plot Options"
        >
          <SmallTreeItem className="smallTreeItem" nodeId="4" label="X Axis">
            <RadioGroup
              className={styles.radioGroupWrapper}
              name="xAxis"
              value={axisViews.xAxis.value}
              onChange={handleAxisViews}
              row
            >
              <FormControlLabel
                value={axisViews.xAxis.name[0]}
                control={<Radio size="small" />}
                label={axisViews.xAxis.label[0]}
              />
              <FormControlLabel
                value={axisViews.xAxis.name[1]}
                control={<Radio size="small" />}
                label={axisViews.xAxis.label[1]}
              />
            </RadioGroup>
          </SmallTreeItem>

          <SmallTreeItem className="smallTreeItem" nodeId="5" label="Y Axis">
            <RadioGroup
              className={styles.radioGroupWrapper}
              name="yAxis"
              value={axisViews.yAxis.value}
              onChange={handleAxisViews}
            >
              <FormControlLabel
                value={axisViews.yAxis.name[0]}
                control={<Radio size="small" />}
                label={
                  <Typography
                    component="p"
                    dangerouslySetInnerHTML={{
                      __html: axisViews.yAxis.label[0],
                    }}
                  />
                }
              />
              <FormControlLabel
                value={axisViews.yAxis.name[1]}
                control={<Radio size="small" />}
                label={
                  <Typography
                    component="p"
                    dangerouslySetInnerHTML={{
                      __html: axisViews.yAxis.label[1],
                    }}
                  />
                }
              />
              <FormControlLabel
                value={axisViews.yAxis.name[2]}
                control={<Radio size="small" />}
                label={
                  <Typography
                    component="p"
                    dangerouslySetInnerHTML={{
                      __html: axisViews.yAxis.label[2],
                    }}
                  />
                }
              />
              <FormControlLabel
                value={axisViews.yAxis.name[3]}
                control={<Radio size="small" />}
                label={
                  <Typography
                    component="p"
                    dangerouslySetInnerHTML={{
                      __html: axisViews.yAxis.label[3],
                    }}
                  />
                }
              />
              <FormControlLabel
                value={axisViews.yAxis.name[4]}
                control={<Radio size="small" />}
                label={
                  <Typography
                    component="p"
                    dangerouslySetInnerHTML={{
                      __html: axisViews.yAxis.label[4],
                    }}
                  />
                }
              />
              <FormControlLabel
                value={axisViews.yAxis.name[5]}
                control={<Radio size="small" />}
                label={
                  <Typography
                    component="p"
                    dangerouslySetInnerHTML={{
                      __html: axisViews.yAxis.label[5],
                    }}
                  />
                }
              />
            </RadioGroup>
          </SmallTreeItem>
        </LargeStyledTreeItem>

        <LargeStyledTreeItem
          className="largeTreeItem"
          label="Energy Band"
          nodeId="3"
        >
          {!isEmptyObject(groupedLegends) &&
            Object.keys(groupedLegends).map((outerKey) => (
              <SmallTreeItem
                nodeId={outerKey}
                key={outerKey}
                label={
                  <Label fontSize="14px">
                    <Checkbox
                      size="small"
                      checked={groupedLegends[outerKey].show}
                      onClick={(e) => e.stopPropagation()}
                      onChange={(e) => handleCheckboxChange(e, outerKey)}
                    />
                    {orderList[outerKey]}
                  </Label>
                }
              >
                {Object.entries(groupedLegends[outerKey].subCategories).map(
                  ([innerKey, { show, color }]) => {
                    return (
                      <SmallTreeItem
                        nodeId={`${outerKey}-${innerKey}`}
                        key={innerKey}
                        label={
                          <Label fontSize="14px">
                            <Checkbox
                              size="small"
                              checked={show}
                              onClick={(e) => e.stopPropagation()}
                              onChange={(e) =>
                                handleCheckboxChange(
                                  e,
                                  `${outerKey}&${innerKey}`
                                )
                              }
                            />
                            {innerKey}
                            <ColorCircle color={color} />
                          </Label>
                        }
                      />
                    );
                  }
                )}
              </SmallTreeItem>
            ))}
        </LargeStyledTreeItem>
      </TreeView>
    </Box>
  );
};

export default FilterPlotData;
