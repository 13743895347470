import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../axiosConfig";

const initialState = {
  uuid: "",
  coordinates: {
    nu: [],
    nuFnu: [],
  },
  incomingCoordinates: [],
  bestCoordinates: {
    nu: [],
    nuFnu: [],
  },
  uploadDataCoordinates: {
    x: [],
    y: [],
    dy: [],
  },
  uploadCsvErrorText: "",
  minMaxCoordinates: { nu: [null, null], nuFnu: [null, null] },
  plotDataErrorText: "",
  showPlotLoader: false,
  loading: false,
  axisViews: {
    xAxis: {
      value: "hz",
      name: ["hz"],
      label: ["Hz"],
    },
    yAxis: {
      value: "erg",
      name: ["erg"],
      label: ["𝜈𝐹(𝜈) [erg cm<sup>-2</sup> s<sup>-1</sup>]"],
    },
  },
  error: null,
  searchParams: {
    checkboxData: {
      value: "SSC",
      name: ["SSC", "EIC", "Hadronic"],
      label: ["SSC", "EIC", "Hadronic"],
    },
    sscInputsData: [
      { id: 1, value: "", label: "z", name: "z" },
      { id: 2, value: false, label: "EBL", name: "" },
      { id: 3, value: "", label: "p", name: "spectral_index" },
      { id: 4, value: "", label: "δ", name: "lorentz_factor" },
      { id: 5, value: "", label: "log10(γmax)", name: "log_gamma_cut" },
      {
        id: 6,
        value: "",
        label: "log10(γmin)",
        name: "log_gamma_min",
        isWithCheckbox: true,
        checked: false,
      },
      { id: 7, value: "", label: "log10(B/[G])", name: "log_B" },
      { id: 8, value: "", label: "log10(R/[cm])", name: "log_radius" },
      {
        id: 9,
        value: "",
        label: "log10(Le/[erg s⁻¹])",
        name: "log_electron_luminosity",
      },
    ],
    eicInputsData: [
      { id: 1, value: "", label: "z", name: "z" },
      { id: 2, value: false, label: "EBL", name: "" },
      { id: 3, value: "", label: "p", name: "spectral_index" },
      { id: 4, value: "", label: "δ", name: "lorentz_factor" },
      { id: 5, value: "", label: "log10(γmax)", name: "log_gamma_cut" },
      {
        id: 6,
        value: "",
        label: "log10(γmin)",
        name: "log_gamma_min",
        isWithCheckbox: true,
        checked: false,
      },
      { id: 7, value: "", label: "log10(B/[G])", name: "log_B" },
      { id: 8, value: "", label: "log10(R/[cm])", name: "log_radius" },
      {
        id: 9,
        value: "",
        label: "log10(Le/[erg s⁻¹])",
        name: "log_electron_luminosity",
      },
      {
        id: 10,
        value: "",
        label: "log10(Ld/[erg s⁻¹])",
        name: "log_Ld",
        isWithCheckbox: true,
        checked: false,
      },
      {
        id: 11,
        value: "",
        label: "log10(M⊙)",
        name: "log_MBH",
        isWithCheckbox: true,
        checked: false,
      },
      {
        id: 12,
        value: "15.393",
        label: "log10(νBLR)",
        name: "log_nu_BLR",
        isWithCheckbox: true,
        checked: true,
      },
      {
        id: 13,
        value: "13.477",
        label: "log10(νDT)",
        name: "log_nu_DT",
        isWithCheckbox: true,
        checked: true,
      },
    ],
  },
  emailInput: { id: 1, value: "", name: "Email" },
  pdfUrl: "",
  bestParameters: {},
  successfulSentEmail: null,
  fitPlotErrorText: "",
  uploadedFile: {
    flux: [],
    flux_err: [],
    frequency: [],
  },
};

// Async thunk
export const fetchTheoreticalModelingPlot = createAsyncThunk(
  "theoreticalModeling/fetchCoordinates",
  async (_, { getState, rejectWithValue }) => {
    const { checkboxData, sscInputsData, eicInputsData } =
      getState().theoreticalModeling.searchParams;

    let data = {};

    if (checkboxData.value === "SSC") {
      data = {
        parameters: {
          log_B: Number(sscInputsData[6].value),
          log_electron_luminosity: Number(sscInputsData[8].value),
          log_gamma_cut: Number(sscInputsData[4].value),
          log_gamma_min: Number(sscInputsData[5].value),
          log_radius: Number(sscInputsData[7].value),
          lorentz_factor: Number(sscInputsData[3].value),
          spectral_index: Number(sscInputsData[2].value),
        },
        z: Number(sscInputsData[0].value),
        ebl: sscInputsData[1].value,
        model_type: "SSC",
      };
    } else if (checkboxData.value === "EIC") {
      data = {
        parameters: {
          log_B: Number(eicInputsData[6].value),
          log_electron_luminosity: Number(eicInputsData[8].value),
          log_gamma_cut: Number(eicInputsData[4].value),
          log_gamma_min: Number(eicInputsData[5].value),
          log_radius: Number(eicInputsData[7].value),
          lorentz_factor: Number(eicInputsData[3].value),
          spectral_index: Number(eicInputsData[2].value),
          log_Ld: Number(eicInputsData[9].value),
          log_MBH: Number(eicInputsData[10].value),
          log_nu_BLR: Number(eicInputsData[11].value),
          log_nu_DT: Number(eicInputsData[12].value),
        },
        z: Number(eicInputsData[0].value),
        ebl: eicInputsData[1].value,
        model_type: "EIC",
      };
    }

    try {
      const response = await axios.post(`/modeling/inference/`, data, {
        headers: { "Content-Type": "application/json" },
      });

      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchDataFromURL = createAsyncThunk(
  "theoreticalModeling/fetchDataFromURL",
  async ({ uuid }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/modeling/batch_result/${uuid}`);

      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchTheoreticalModelingSendEmail = createAsyncThunk(
  "theoreticalModeling/fetchEmail",
  async (formData, { getState, rejectWithValue }) => {
    const { emailInput, searchParams } = getState().theoreticalModeling;

    const eicInputConstraints = {
      z: { min: 0, max: 10 },
      δ: { min: 3, max: 50 },
      "log10(R/[cm])": { min: 15, max: 18 },
      "log10(γmin)": { min: 1.5, max: 5 },
      "log10(γmax)": { min: 2, max: 6 },
      p: { min: 1.8, max: 5 },
      "log10(Le/[erg s⁻¹])": { min: 42, max: 48 },
      "log10(B/[G])": { min: -3, max: 2.5 },
      "log10(Ld/[erg s⁻¹])": { min: 43.5, max: 47 },
      "log10(M⊙)": { min: 7, max: 10 },
      "log10(νBLR)": { min: 14.5, max: 16 },
      "log10(νDT)": { min: 12.5, max: 14 },
    };

    if (searchParams.checkboxData.value === "SSC") {
      if (searchParams.sscInputsData[5].checked) {
        if (
          searchParams.sscInputsData[5].value < 1.5 ||
          searchParams.sscInputsData[5].value > 5
        ) {
          return rejectWithValue({
            error: "The value is out of the acceptable range (1.5 to 5)",
          });
        } else {
          formData.append(
            "fixed_parameters",
            JSON.stringify({
              log_gamma_min: Number(searchParams.sscInputsData[5].value),
            })
          );
        }
      }

      formData.append("z", searchParams.sscInputsData[0].value);
      formData.append("ebl", searchParams.sscInputsData[1].value);
      formData.append("email", emailInput.value);
    } else if (searchParams.checkboxData.value === "EIC") {
      let arr = [];
      searchParams.eicInputsData.map((input) => {
        if (input.isWithCheckbox && input.checked) {
          if (
            input.value < eicInputConstraints[input.label].min ||
            input.value > eicInputConstraints[input.label].max
          ) {
            return arr.push(
              `The value is out of the acceptable range (${
                eicInputConstraints[input.label].min
              } to ${eicInputConstraints[input.label].max})`
            );
          } else return null;
        } else return null;
      });

      if (arr.length > 0) {
        return rejectWithValue({ error: arr.join(", ") });
      } else {
        let inputsObj = searchParams.eicInputsData
          .filter((input) => input.isWithCheckbox && input.checked)
          .reduce((acc, input) => {
            acc[input.name] = Number(input.value);
            return acc;
          }, {});

        formData.append("fixed_parameters", JSON.stringify(inputsObj));
      }

      formData.append("z", searchParams.eicInputsData[0].value);
      formData.append("ebl", searchParams.eicInputsData[1].value);
      formData.append("email", emailInput.value);
    }
    formData.append("model_type", searchParams.checkboxData.value);

    try {
      const response = await axios.post(`/modeling/batch_inference/`, formData);

      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const uploadCsvFile = createAsyncThunk(
  "theoreticalModeling/uploadCsvFile",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axios.post("/modeling/csv_to_json/", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// slice
export const theoreticalModelingSlice = createSlice({
  name: "theoreticalModeling",
  initialState,
  reducers: {
    setCoordinates: (state, action) => {
      state.coordinates = action.payload;
    },
    setMinMaxCoordinates: (state, action) => {
      state.minMaxCoordinates = action.payload;
    },
    setSscSearchParams: (state, action) => {
      let { sscInputsData } = state.searchParams;
      const { id, value, checkbox } = action.payload;

      const inputIndex = sscInputsData.findIndex((input) => input.id === id);

      if (inputIndex !== -1) {
        if (checkbox !== undefined) {
          sscInputsData[inputIndex].checked = checkbox;
        } else {
          sscInputsData[inputIndex].value = value;
        }
      }
    },
    setEicSearchParams: (state, action) => {
      let { eicInputsData } = state.searchParams;
      const { id, value, checkbox } = action.payload;

      const inputIndex = eicInputsData.findIndex((input) => input.id === id);

      if (inputIndex !== -1) {
        if (checkbox !== undefined) {
          eicInputsData[inputIndex].checked = checkbox;
        } else {
          eicInputsData[inputIndex].value = value;
        }
      }
    },
    setModelType: (state, action) => {
      state.searchParams = {
        ...state.searchParams,
        checkboxData: action.payload,
      };
    },
    setEmailInput: (state, action) => {
      state.emailInput = action.payload;
    },
    setPlotDataErrorText: (state, action) => {
      state.plotDataErrorText = action.payload;
    },
    setShowPlotLoader: (state, action) => {
      state.showPlotLoader = action.payload;
    },
    setAxisViews: (state, action) => {
      state.axisViews = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setUuid: (state, action) => {
      state.uuid = action.payload;
    },
    setUploadDataCoordinates: (state, action) => {
      state.uploadDataCoordinates = action.payload;
    },
    setSuccessfulSentEmail: (state, action) => {
      state.successfulSentEmail = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTheoreticalModelingPlot.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTheoreticalModelingPlot.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        if (action.payload) {
          state.coordinates = {
            nu: action.payload.data.best.nu,
            nuFnu: action.payload.data.best.nuFnu,
          };
          state.minMaxCoordinates = action.payload.data.best.min_max;
        } else {
          state.coordinates = { nu: [], nuFnu: [] };
        }
      })
      .addCase(fetchTheoreticalModelingPlot.rejected, (state, action) => {
        state.loading = false;
        if (action.payload && action.payload.status === 503) {
          state.optionsError = "Service unavailable";
        }
        state.optionsError = `Error fetching search results: ${action.error}`;
      })
      .addCase(uploadCsvFile.pending, (state) => {
        state.loading = true;
        state.uploadCsvErrorText = "";
      })
      .addCase(uploadCsvFile.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload.status === "success") {
          state.uploadDataCoordinates = action.payload.data;
        }
        state.uploadCsvErrorText = "";
      })
      .addCase(uploadCsvFile.rejected, (state, action) => {
        state.loading = false;

        state.uploadCsvErrorText =
          action.payload.error || "Error uploading CSV file";
      })
      .addCase(fetchDataFromURL.pending, (state) => {
        state.loading = true;
        state.uploadCsvErrorText = "";
      })
      .addCase(fetchDataFromURL.fulfilled, (state, action) => {
        const {
          csv_best_model_link,
          csv_best_parameters_link,
          pdf_link,
          best_parameters,
          fixed_parameters,
          model_type,
          data,
          z,
          uploaded_file,
        } = action.payload;

        const incomingCoordinates = [];

        for (let key in data) {
          if (data.hasOwnProperty(key) && key !== "best") {
            incomingCoordinates.push(data[key]);
          }
        }
        if (model_type === "SSC") {
          const updatedInputsData = state.searchParams.sscInputsData.map(
            (input) => {
              let value =
                best_parameters[input.name]?.value.toString() ||
                fixed_parameters[input.name]?.value.toString();

              if (value?.includes(".")) {
                let parts = value.split(".");
                if (parts[1].length > 3) {
                  best_parameters[input.name].value =
                    parts[0] + "." + parts[1].substring(0, 3);
                }
              }

              if (input.name === "z" && z) {
                return {
                  ...input,
                  value: z,
                };
              }

              if (best_parameters.hasOwnProperty(input.name)) {
                return {
                  ...input,
                  value: best_parameters[input.name].value,
                };
              } else if (
                fixed_parameters.hasOwnProperty(input.name) &&
                input.isWithCheckbox
              ) {
                return {
                  ...input,
                  value: fixed_parameters[input.name].value,
                  checked: true,
                };
              }
              return input;
            }
          );

          return {
            ...state,
            loading: false,
            incomingCoordinates: incomingCoordinates,
            bestCoordinates: data.best,
            searchParams: {
              ...state.searchParams,
              checkboxData: {
                ...state.searchParams.checkboxData,
                value: model_type,
              },
              sscInputsData: updatedInputsData,
            },
            pdfUrl: pdf_link,
            bestParametersLink: csv_best_parameters_link,
            bestModelLink: csv_best_model_link,
            uploadedFile: uploaded_file,
          };
        } else if (model_type === "EIC") {
          const updatedInputsData = state.searchParams.eicInputsData.map(
            (input) => {
              let value =
                best_parameters[input.name]?.value.toString() ||
                fixed_parameters[input.name]?.value.toString();

              if (value?.includes(".")) {
                let parts = value.split(".");
                if (parts[1].length > 3) {
                  best_parameters[input.name].value =
                    parts[0] + "." + parts[1].substring(0, 3);
                }
              }

              if (input.name === "z" && z) {
                return {
                  ...input,
                  value: z,
                };
              }

              if (best_parameters.hasOwnProperty(input.name)) {
                return {
                  ...input,
                  value: best_parameters[input.name].value,
                };
              } else if (fixed_parameters.hasOwnProperty(input.name)) {
                return {
                  ...input,
                  value: fixed_parameters[input.name].value,
                  checked: true,
                };
              }
              return input;
            }
          );

          return {
            ...state,
            loading: false,
            incomingCoordinates: incomingCoordinates,
            bestCoordinates: data.best,
            searchParams: {
              ...state.searchParams,
              checkboxData: {
                ...state.searchParams.checkboxData,
                value: model_type,
              },
              eicInputsData: updatedInputsData,
            },
            pdfUrl: pdf_link,
            bestParametersLink: csv_best_parameters_link,
            bestModelLink: csv_best_model_link,
            uploadedFile: uploaded_file,
          };
        }
      })
      .addCase(fetchDataFromURL.rejected, (state, action) => {
        state.loading = false;

        state.uploadCsvErrorText = action.payload.error || "Error - try again";
      })
      .addCase(fetchTheoreticalModelingSendEmail.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchTheoreticalModelingSendEmail.fulfilled, (state, action) => {
        state.loading = false;

        if (action.payload.batch_result_id) {
          state.successfulSentEmail = true;
        }
        state.uploadCsvErrorText = "";
      })
      .addCase(fetchTheoreticalModelingSendEmail.rejected, (state, action) => {
        state.loading = false;
        state.successfulSentEmail = false;
        state.fitPlotErrorText = action.payload.error || "Error - try again";
      });
  },
});

export const {
  setUuid,
  setAxisViews,
  setSscSearchParams,
  setEicSearchParams,
  setEmailInput,
  setCoordinates,
  setPlotDataErrorText,
  setMinMaxCoordinates,
  setShowPlotLoader,
  setUploadDataCoordinates,
  setSuccessfulSentEmail,
  setModelType,
} = theoreticalModelingSlice.actions;

export const selectUuid = (state) => state.theoreticalModeling.uuid;
export const selectAxisViews = (state) => state.theoreticalModeling.axisViews;
export const selectCoordinates = (state) =>
  state.theoreticalModeling.coordinates;
export const selectPlotDataErrorText = (state) =>
  state.theoreticalModeling.plotDataErrorText;
export const selectShowPlotLoader = (state) =>
  state.theoreticalModeling.showPlotLoader;
export const selectMinMaxCoordinates = (state) =>
  state.theoreticalModeling.minMaxCoordinates;
export const selectSearchParams = (state) =>
  state.theoreticalModeling.searchParams;
export const selectEmailInput = (state) => state.theoreticalModeling.emailInput;
export const selectError = (state) => state.theoreticalModeling.error;
export const selectUploadDataCoordinates = (state) =>
  state.theoreticalModeling.uploadDataCoordinates;
export const selectUploadCsvErrorText = (state) =>
  state.theoreticalModeling.uploadCsvErrorText;
export const selectPdfUrl = (state) => state.theoreticalModeling.pdfUrl;
export const selectBestModelLink = (state) =>
  state.theoreticalModeling.bestModelLink;
export const selectUploadedFile = (state) =>
  state.theoreticalModeling.uploadedFile;
export const selectBestParametersLink = (state) =>
  state.theoreticalModeling.bestParametersLink;
export const selectBestParameters = (state) =>
  state.theoreticalModeling.bestParameters;
export const selectBestCoordinates = (state) =>
  state.theoreticalModeling.bestCoordinates;
export const selectIncomingCoordinates = (state) =>
  state.theoreticalModeling.incomingCoordinates;
export const selectSuccessfulSentEmail = (state) =>
  state.theoreticalModeling.successfulSentEmail;
export const selectFitPlotErrorText = (state) =>
  state.theoreticalModeling.fitPlotErrorText;

export default theoreticalModelingSlice.reducer;
