import React from "react";
import PlotDataInput from "./PlotDataInput";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  handleShowSections,
  selectRaDecValue,
  selectSearchText,
  selectShowPlotLoader,
  selectShowSections,
  selectShowSourceInfo,
  selectSourceName,
  selectValueDb,
} from "../features/dataAccess/dataAccessSlice";
import ForceRunCheckbox from "./ForceRunCheckbox";
import DataPolicy from "./DataPolicy";
import DownloadCSV from "./DownloadCSV";
import styles from "../styles/SearchPlotWithInputData.module.css";
import SelectCoordinatesFromTable from "./SelectCoordinatesFromTable";

const SearchPlot = () => {
  const dispatch = useDispatch();
  const searchText = useSelector(selectSearchText);
  const showSourceInfo = useSelector(selectShowSourceInfo);
  const showPlotLoader = useSelector(selectShowPlotLoader);
  const showSections = useSelector(selectShowSections);
  const { ra, dec } = useSelector(selectRaDecValue);
  const sourceName = useSelector(selectSourceName);
  const valueDB = useSelector(selectValueDb);

  const handleShowSectionsButtonClick = () => {
    dispatch(handleShowSections({ sourceName, ra, dec, valueDB }));
  };

  return (
    <div className={styles.wrapper} id="scrollToDataWithInputData">
      <div className={styles.leftPart}>
        <SelectCoordinatesFromTable />

        <PlotDataInput />
        <div className={styles.buttonsWrapper}>
          <Button
            sx={{ height: "fit-content" }}
            variant="contained"
            size="medium"
            onClick={handleShowSectionsButtonClick}
            disabled={searchText.length <= 2}
          >
            Plot
          </Button>

          <ForceRunCheckbox />
        </div>
      </div>

      {showSourceInfo && <DataPolicy />}

      {!showPlotLoader && showSections && <DownloadCSV />}
    </div>
  );
};

export default SearchPlot;
